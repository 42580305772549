import fetch from "cross-fetch"
import { useAuth0 } from "@auth0/auth0-react"

const useBraintree = () => {
  const { getAccessTokenSilently } = useAuth0()

  const getToken = async () => {
    const authToken = await getAccessTokenSilently()
    const response = await fetch("/.netlify/functions/braintree-get-token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })

    const { token, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return token
  }

  const getPaymentMethodNonce = async paymentMethodToken => {
    const response = await fetch(
      "/.netlify/functions/braintree-get-payment-method-nonce",
      {
        method: "POST",
        body: JSON.stringify({ paymentMethodToken }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const { paymentMethodNonce, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }
    return paymentMethodNonce
  }

  const addUserPaymentMethod = async ({ paymentMethodNonce, ...others }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-add-payment-method",
      {
        method: "POST",
        body: JSON.stringify({ paymentMethodNonce, ...others }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const data = await response.json()
    const { paymentMethodToken, error } = data

    if (response.status !== 200) {
      throw error
    }

    return paymentMethodToken
  }

  const createCustomer = async ({
    firstname,
    lastname,
    billing_address,
    business_address,
    phone_number,
    email,
    tax_name,
    vat_number,
  }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-create-customer",
      {
        method: "POST",
        body: JSON.stringify({
          firstname,
          lastname,
          billing_address,
          business_address,
          phone_number,
          email,
          tax_name,
          vat_number,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { customer, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return customer
  }

  const subscribe = async ({
    paymentMethodNonce,
    deviceData,
    firstname,
    lastname,
    billing_address,
    business_address,
    phone_number,
    email,
    tax_name,
    vat_number,
    coupon_code = "",
  }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/.netlify/functions/braintree-subscribe", {
      method: "POST",
      body: JSON.stringify({
        paymentMethodNonce,
        deviceData,
        firstname,
        lastname,
        billing_address,
        business_address,
        phone_number,
        email,
        tax_name,
        vat_number,
        coupon_code,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { subscription, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return subscription
  }

  const checkout = async (payload, purchaseId) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/.netlify/functions/braintree-checkout", {
      method: "POST",
      body: JSON.stringify({
        paymentMethodNonce: payload.nonce,
        couponCode: payload.couponCode,
        purchaseId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { transaction, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return transaction
  }

  const getUserPaymentMethod = async ({ billingProfileId }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-get-payment-method",
      {
        method: "POST",
        body: JSON.stringify({ billingProfileId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { paymentMethod, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }
    return paymentMethod
  }

  const updateUserPaymentMethod = async data => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-update-payment-method",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { paymentMethod, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }
    return paymentMethod
  }

  const deleteUserPaymentMethod = async id => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-delete-payment-method",
      {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { result, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }
    return result
  }

  const cancelSubscription = async id => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-cancel-subscription",
      {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { subscription, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return subscription
  }

  const autoRenew = async () => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-subscription-auto-renew",
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { subscription, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return subscription
  }

  return {
    getPaymentMethodNonce,
    addUserPaymentMethod,
    getUserPaymentMethod,
    updateUserPaymentMethod,
    deleteUserPaymentMethod,
    subscribe,
    createCustomer,
    getToken,
    checkout,
    cancelSubscription,
    autoRenew,
  }
}

export default useBraintree
