import * as prettyMilliseconds from "pretty-ms"
import { Badge, Col, Modal, Row } from "antd"
import _ from "lodash"
import VideoTile from "./components/VideoTile"
import CourseTile from "./components/CourseTile"
import AuthorTile from "./components/AuthorTile"
import AtsCard from "./components/AtsCard"
import CourseHeader from "./components/CourseHeader"
import AuthorHeader from "./components/AuthorHeader"
import VideoHeader from "./components/VideoHeader"
import AtsCarousel from "./components/AtsCarousel"
import { navigate } from "gatsby"
import React from "react"
import { FREE, ON_DEMAND, SUBSCRIPTION } from "./enums/PricePolicies"
import ChannelTile from "./components/ChannelTile"
import moment from "moment"
import CategoryTile from "./components/CategoryTile"
import withTileBadgeCheck from "./components/withTileBadgeCheck"
import * as Sentry from "@sentry/browser"
import QueryString from "query-string"
import getSlug from "speakingurl"
import ALL_LANGS from "./static/locale/pathNames"
import i18n from "i18next"
import i18next from "i18next"
import GenericHeader from "./components/GenericHeader"
import CertificationTile from "./components/CertificationTile"
import { BUNDLE, CERTIFICATION, COURSE, VIDEO } from "./enums/ItemTypes"
import { defaultTheme } from "./theme"
import WebinarTile from "./components/WebinarTile"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "./constants/imageSizes"
import BundleTile from "./components/BundleTile"
import BundleHeader from "./components/BundleHeader"

const _getErrorMessageByCode = code => {
  // t("error:No payment method is available.")
  if (code === "undefined") {
    return i18n.t("error:genericError")
  }

  if (!code) {
    return i18n.t("error:genericError")
  }
  
  return i18next.t(`error:${code}`)
}

const _translatePath = key => {
  const lang = ALL_LANGS[process.env.GATSBY_LANG]

  return lang[key] ?? key
}

export const formatDuration = (duration, options) => {
  if (isNaN(duration)) {
    return i18n.t("label:unknownDuration")
  }

  let _options = options
    ? { ...options }
    : { colonNotation: true, secondsDecimalDigits: 0 }
  return prettyMilliseconds(Number(duration) * 1000, _options)
}

export const formatDate = (date, { format = "lll" } = {}) => {
  const result = moment(date).format(format)

  if (process.env.GATSBY_COUNTRY === "US") {
    return `${result} CST`
  }

  return result
}

export const uiHandleError = ({
  error,
  action,
  message,
  actionText = "Ok",
}) => {
  devLog({ error })
  sendErrorToSentry(error)
  const modal = Modal.error({
    content: message || _getErrorMessageByCode(error?.code || error?.message),
    onOk: () => {
      modal.destroy()
      action && action()
    },
    onCancel: () => {
      modal.destroy()
    },
    okText: actionText,
    closable: true,
    cancelText: i18n.t("button:close"),
  })
}

export const uiHandleWarning = ({ action, message }) => {
  const modal = Modal.warning({
    content: message || i18n.t("error:genericError"),
    onOk: () => {
      modal.destroy()
      action && action()
    },
  })
}

export const uiHandleSuccess = ({ action, message }) => {
  Modal.success({
    content: message || i18n.t("message:operationCompleted"),
    onOk: () => {
      action ? action() : window.history.back()
    },
  })
}

export const getUserDisplayName = user => {
  devLog({ user })

  const {
    basic_profile,
    given_name,
    family_name,
    nickname,
    name,
    displayname,
  } = user

  if (user.basic_profile) {
    const { firstname, lastname } = user.basic_profile
    return `${firstname} ${lastname}`
  }

  if (given_name && family_name) {
    return `${given_name} ${family_name}`
  }

  return user.email
}

export const getCurrencySymbol = currency => {
  const symbols = {
    EUR: "€",
    USD: "$",
  }

  return symbols[currency] || currency
}

export const formatNumber = ({
  value,
  precision,
  decimalSeparator,
  groupSeparator,
}) => {
  const val = String(value)
  const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/)

  if (!cells || val === "-") {
    return val
  } else {
    const negative = cells[1]
    let int = cells[2] || "0"
    let decimal = cells[4] || ""

    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator)

    if (typeof precision === "number") {
      decimal = _.padEnd(decimal, precision, "0").slice(0, precision)
    }

    return `${negative}${int}${decimalSeparator}${decimal}`
  }
}

export const formatPrice = (price, currency = process.env.GATSBY_CURRENCY) => {
  if (isNaN(price)) {
    return "--"
  }

  const currencySymbol = getCurrencySymbol(currency)
  return `${formatNumber({
    value: price,
    precision: 2,
    decimalSeparator: process.env.GATSBY_DECIMAL_SEPARATOR || ".",
    groupSeparator: process.env.GATSBY_GROUP_SEPARATOR || ",",
  })} ${currencySymbol}`
}

export const getCourseTotalTime = course => {
  const { videos } = course
  return videos
    ? videos.reduce((accumulator, { video }) => {
        return accumulator + (video?.duration || 0)
      }, 0)
    : 0
}

export const getCourseAuthorList = course => {
  const { videos } = course

  if (!videos) {
    return []
  }

  const allAuthorList = videos.reduce((authorList, video) => {
    return [
      ...authorList,
      ...(video.author_rels?.map(item => item.author) || []),
    ]
  }, [])

  return _.uniqBy(allAuthorList, "id")
}

const _isUndefined = object => {
  return typeof object === "undefined"
}

export const isUndefined = _isUndefined

export const getIsFavorite = (userFavorites, itemId) => {
  if (userFavorites) {
    return (
      userFavorites.find(({ item_id }) => itemId === item_id)?.is_favorite ??
      false
    )
  }

  return false
}

export const getLatestItems = (courses, videos) => {
  const maxCount = 20
  let videoCount = maxCount / 2
  let courseCount = maxCount / 2
  if (courses.length < maxCount / 2) {
    courseCount = courses.length
    videoCount = maxCount - courseCount
  }

  if (videos.count < maxCount / 2) {
    videoCount = videos.length
    courseCount = maxCount - courseCount
  }

  const result = [
    ...courses.slice(0, courseCount),
    ...videos.slice(0, videoCount),
  ]

  return _.uniqBy(result, "id")
}

const TileComponentList = {
  default: AtsCard,
  ats_videos: VideoTile,
  ats_courses: CourseTile,
  ats_authors: AuthorTile,
  ats_channels: ChannelTile,
  ats_categories: CategoryTile,
  ats_certifications: CertificationTile,
  ats_webinars: WebinarTile,
  ats_bundles: BundleTile,
  videos: VideoTile,
  courses: CourseTile,
  authors: AuthorTile,
  channels: ChannelTile,
  categories: CategoryTile,
  certifications: CertificationTile,
  webinars: WebinarTile,
  bundles: BundleTile,
}

const TilePathnameList = {
  ats_videos: _translatePath("video"),
  ats_courses: _translatePath("course"),
  ats_authors: _translatePath("author"),
  ats_channels: _translatePath("channel"),
  ats_categories: _translatePath("category"),
  ats_certifications: _translatePath("certification"),
  ats_bundles: _translatePath("bundle"),
  videos: _translatePath("video"),
  courses: _translatePath("course"),
  authors: _translatePath("author"),
  channels: _translatePath("channel"),
  categories: _translatePath("category"),
  certifications: _translatePath("certification"),
  bundles: _translatePath("bundle"),
}

const HeaderComponentList = {
  default: GenericHeader,
  ats_videos: VideoHeader,
  ats_courses: CourseHeader,
  ats_authors: AuthorHeader,
  ats_bundles: BundleHeader,
}

export const getTileComponent = typename => {
  return TileComponentList[typename] || TileComponentList.default
}

export const getHeaderComponent = typename => {
  return HeaderComponentList[typename] || HeaderComponentList.default
}

export const getTilePathname = typename => {
  return TilePathnameList[typename]
}

const _renderCarouselItem = (
  item,
  index,
  showRank = false,
  checkBadge = true,
  isVertical = false
) => {
  if (!item) {
    return null
  }

  let TileComponent = getTileComponent(item.__typename)
  if (checkBadge) {
    TileComponent = withTileBadgeCheck(TileComponent)
  }

  const slug = getSlug(item.seo?.slug ?? item.title ?? item.name)

  const handleOnPlay = async () => {
    if (["ats_videos", "videos"].includes(item.__typename)) {
      navigate(`${translatePath("/watch")}/${slug}`)
    }

    if (["ats_courses", "courses"].includes(item.__typename)) {
      navigate(`${translatePath("/play-course")}?courseId=${item.id}`)
    }
  }

  const handleClick = evt => {
    if (item.is_external) {
      window.open(item.external_url, "_blank").focus()
      return
    }

    if (item.course_rel?.course_id) {
      navigate(`/go?id=${item.course_rel?.course_id}`)
      return
    }

    navigate(`/${getTilePathname(item.__typename)}/${slug}`)
  }

  return (
    <TileComponent
      isVertical={isVertical}
      key={item.id}
      type={"full"}
      data={item}
      progress={item.progress}
      onPlay={handleOnPlay}
      onClick={handleClick}
      rank={showRank ? index + 1 : null}
    />
  )
}

export const renderCarouselItem = _renderCarouselItem

export const renderCategoryRow = (category, index) => {
  const { id, name } = category
  const mergedData = getCategoryDataSource(category)
  if (!mergedData.length) {
    return null
  }

  const slug = getSlug(category.seo?.slug ?? category.name)

  return (
    <Row key={id}>
      <Col span={24}>
        <AtsCarousel
          title={name}
          url={`/${_translatePath("category")}/${slug}`}
          dataSource={mergedData}
          renderItem={_renderCarouselItem}
        />
      </Col>
    </Row>
  )
}

export const getHomePageData = (homePageSettings, allVideos, allCourses) => {
  const { featured_item_list, suggested_item_list } = homePageSettings

  const merged = [...allVideos, ...allCourses]

  const featuredItemList = []
  const suggestedItemList = []

  if (featured_item_list) {
    merged.map(item => {
      if (featured_item_list.includes(item.id)) {
        featuredItemList.push(item)
      }
    })
  }

  if (suggested_item_list) {
    merged.map(item => {
      if (suggested_item_list.includes(item.id)) {
        suggestedItemList.push(item)
      }
    })
  }

  return {
    featuredItemList,
    suggestedItemList,
  }
}

export const getVideoPlayedRangeData = ({ video, sessionId, videoId }) => {
  if (!video) {
    return null
  }

  const played = video.played
  const result = []
  for (let i = 0; i < played.length; i++) {
    const start = played.start(i)
    const end = played.end(i)
    const duration = end - start
    result.push({
      start,
      end,
      duration,
      video_id: videoId,
      session_id: sessionId,
    })
  }

  return result
}

const _sendGtagEvent = (event, category, data) => {
  if (_isUndefined(window)) {
    return
  }

  if (window.gtag) {
    window.gtag(event, category, { ...data })
  }
}

export const sendSearchEvent = search_term => {
  _sendGtagEvent("event", "search", {
    search_term,
    search: search_term,
    search_text: search_term,
    searchTerm: search_term,
    searchText: search_term,
  })
}

const convertItemToAnalyticsItem = item => {
  const {
    __typename,
    id,
    title,
    subtitle,
    description,
    price,
    price_policy,
    currency,
  } = item
  return {
    id,
    name: title,
    list_name: __typename,
    price,
    price_policy,
  }
}

export const sendViewItemEvent = item => {
  const data = {
    items: [convertItemToAnalyticsItem(item)],
  }
  _sendGtagEvent("event", "view_item", data)
}

export const sendPurchaseEvent = ({ id, price, name }) => {
  // TODO: caso acquisto di un video o abbonamento completato
  // https://developers.google.com/gtagjs/reference/event#purchase
  _pushData({
    event: "purchase",
    currency: process.env.GATSBY_CURRENCY || "EUR",
    value: price,
    items: [
      {
        item_id: id,
        item_name: name,
        price: price,
      },
    ],
  })
}

export const getCategoryDataSource = category => {
  if (!category) {
    return []
  }

  const { video_rels, course_rels } = category
  return _.orderBy(
    [
      ...(video_rels ?? []).map(item => item.video),
      ...(course_rels ?? []).map(item => item.course),
    ],
    "created_at",
    "desc"
  ).filter(({ price_policy }) => price_policy !== ON_DEMAND)
}

export const getPriceBadge = price_policy => {
  const colors = {
    [ON_DEMAND]: "#9A0CEC",
    [FREE]: "#5AC05A",
    [SUBSCRIPTION]: "#EC9A0C",
  }

  const texts = {
    [ON_DEMAND]: i18n.t("label:PURCHASE"),
    [FREE]: i18n.t("label:FREE"),
    [SUBSCRIPTION]: i18n.t("label:SUBSCRIPTION"),
  }

  return (
    <Badge
      style={{ backgroundColor: colors[price_policy] ?? colors[ON_DEMAND] }}
      count={texts[price_policy] ?? texts[ON_DEMAND]}
    />
  )
}

export const getDownloadUrl = (s3Key, options = {}) => {
  const params = { ...options, key: s3Key }
  const search = QueryString.stringify(params)
  return `/.netlify/functions/get-download-url?${search}`
}

export const isSubscriptionValid = subscription => {
  if (!subscription) {
    return false
  }
  const { state, end_at } = subscription
  if (end_at) {
    return moment(end_at).add(2, "day") > moment()
  }

  return state === "Active"
}

export const getMostViewedItemList = async videos => {
  const temp = {} // [{itemId: xxx, duration: xxx}]
  videos.map(video => {
    temp[video.id] =
      video.played_ranges_aggregate?.aggregate?.sum?.duration ?? 0
    if (video.course_rel) {
      temp[video.course_rel.course_id] = temp[video.course_rel.course_id] ?? 0
      temp[video.course_rel.course_id] +=
        video.played_ranges_aggregate?.aggregate?.sum?.duration ?? 0
    }
  })

  const result = Object.keys(temp).map(key => {
    return {
      id: key,
      duration: temp[key],
    }
  })

  return _.orderBy(result, "duration", "desc")
}

const _getAverageRGB = imgEl => {
  let blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0

  if (!context) {
    return defaultRGB
  }

  height = canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width

  context.drawImage(imgEl, 0, 0)

  try {
    data = context.getImageData(0, 0, width, height)
  } catch (e) {
    /* security error, img on diff domain */
    return defaultRGB
  }

  length = data.data.length

  while ((i += blockSize * 4) < length) {
    ++count
    rgb.r += data.data[i]
    rgb.g += data.data[i + 1]
    rgb.b += data.data[i + 2]
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count)
  rgb.g = ~~(rgb.g / count)
  rgb.b = ~~(rgb.b / count)

  return rgb
}

export const getDominantColorFromImage = ({ image, url }) => {
  return new Promise((resolve, reject) => {
    const resolveColor = img => {
      const color = _getAverageRGB(img)
      resolve(color)
      img = null
    }

    let tempImage = image

    if (url) {
      tempImage = new Image()
      tempImage.src = url
    }

    if (!tempImage) {
      return reject(new Error("No image provided"))
    }

    if (tempImage.isComplete) {
      resolveColor(tempImage)
    } else {
      tempImage.onload = () => {
        resolveColor(tempImage)
      }
    }

    tempImage.onerror = reject
  })
}

export const devLog =
  process.env.NODE_ENV === "production" ? () => {} : console.log

export const getErrorMessageByCode = _getErrorMessageByCode

export const sendErrorToSentry = error => {
  if (process.env.NODE_ENV === "production") {
    Sentry.captureException(error)
  }
}

export const translatePath = _translatePath

export const mapItemTypeFromTypename = typename => {
  const types = {
    ats_courses: COURSE,
    ats_videos: VIDEO,
    ats_certifications: CERTIFICATION,
    ats_bundles: BUNDLE,
  }

  return types[typename] ?? typename
}

export const getImageUrl = (item, size = DEFAULT_LANDSCAPE_IMAGE_SIZE) => {
  if (!item) {
    return defaultTheme.defaultLandscape
  }

  if (item?.localFile?.childImageSharp?.gatsbyImageData) {
    return item
  }

  let url = item?.localFile?.childImageSharp.fixed.src
  if (url) {
    return url
  }

  if (item?.s3_key) {
    return getDownloadUrl(item.s3_key, { size })
  }

  return defaultTheme.defaultLandscape
}

export const toPascalCase = str => {
  return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase()
  })
}

const _pushData = data => {
  if (!window.gtag) {
    setTimeout(() => {
      _pushData(data)
    }, 1000)
    return
  }

  window.gtag("event", data.event, data)
}

export const pushAddToCartEvent = ({ id, price, name }) => {
  _pushData({
    event: "add_to_cart",
    currency: process.env.GATSBY_CURRENCY || "EUR",
    value: price,
    items: [{ item_id: id, item_name: name }],
  })
}

export const pushBeginCheckoutEvent = ({ id, price, name }) => {
  _pushData({
    event: "begin_checkout",
    currency: process.env.GATSBY_CURRENCY || "EUR",
    value: price,
    items: [{ item_id: id, item_name: name, price }],
  })
}

export const pushPurchaseEvent = ({ itemId, price, name, transactionId }) => {
  _pushData({
    event: "purchase",
    currency: process.env.GATSBY_CURRENCY || "EUR",
    transaction_id: transactionId,
    value: price,
    items: [{ item_id: itemId, item_name: name, price }],
  })
}

export const addressToString = address => {
  if (!address) {
    return ""
  }

  const { city, region, postal_code, address_line1 } = address

  return `${address_line1}, ${city}, ${region}, ${postal_code}`
}
